import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Acid from "../../images/media/products/acid/acid.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"






const Product = () => (
    <Layout>
      <Seo title="Odnawiający peeling kwasowy - Linia Acid Peel" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="blue">
                    <H1>Odnawiający <br/>peeling kwasowy </H1>
                    <p>Jędrność i wygładzenie</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energetyzujące serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Nawilża na poziomie komórkowym.</li>
                        <li>Redukuje niedoskonałości.</li>
                        <li>Odbudowuje i wyrównuje strukturę skóry.</li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-odnawiajacy-peeling-kwasowy-do-twarzy-50-ml-000000000000407506.html" color="blue">Kup w Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-acid-peel-odnawiajacy-peeling-kwasowy-50-ml.html" color="blue">Kup w Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-acid-peel-aha-pha-odnawiajacy-peeling-kwasowy-50-ml-0107782" color="blue">Kup w Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176923-Oxygenetic_Acid_Peel_AHAPHA_odnawiajacy_peeling_kwasowy_50_ml" color="blue">Kup w DOZ</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energetyzujące serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="blue" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar>
                    <Link to="/produkty/przeciwstarzeniowy-peeling">
                        <StaticImage
                            src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="przeciwstarzeniowy-peeling"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="blue">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="blue">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>Preparat pobudzając produkcję kwasu hialuronowego silnie nawilża nawet bardzo wymagającą cerę.</strong>
                                </p>
                                <p>
                                    Zmniejsza przeznaskórkową utratę wody (TEWL), chroniąc ją przed odwodnieniem. Pobudza produkcję kolagenu i elastyny, spłycając zmarszczki i opóźniając procesy starzenia.
                                </p>
                                <p>
                                    Peeling działając w głębokich warstwach skóry, zmniejsza produkcję sebum, zwęża pory i niweluje niedoskonałości. Dodatkowo stymulując metabolizm komórkowy zapewnia promienny wygląd skóry.
                                </p>
                                <p>
                                    Preparat polecany dla skóry wymagającej regeneracji, nawilżenia i rozświetlenia, a także takiej z widocznymi niedoskonałościami, przebarwieniami, zmarszczkami czy rozszerzonymi porami.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/acid/products/odnawiajacy-peeling_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="Energetyzujące serum"
                                />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>Nałożyć cienką warstwę peelingu wieczorem na skórę twarzy, szyi i dekoltu. Stosować w formie kuracji przez 3 tygodnie. W pierwszym tygodniu preparat aplikować co drugi dzień. Następnie stosować codziennie, aż do zakończenia kuracji. Dla podtrzymania efektu stosować 1-2 razy w tygodniu. Podczas kuracji używać filtrów UV oraz unikać ekspozycji na słońce. Unikać bezpośredniego kontaktu z oczami.</p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>50ml</p>
                                <span className="acid-peel">
                                    <Acid/>
                                </span>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Kwas migdałowy" desc="Kwas AHA, który reguluje odnowę komórkową, złuszczając wierzchnie warstwy naskórka, tym samym poprawia strukturę i koloryt skóry oraz działa przeciwzmarszczkowo. Dogłębnie odbudowuje skórę, a także działa antybakteryjnie. Stymuluje produkcję kwasu hialuronowego w skórze, przywracając jej jędrność. Dodatkowo niweluje zmiany trądzikowe." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                    <Ingredient name="Glukonolakton" desc="Kwas PHA o silnych właściwościach nawilżających, pomaga utrzymać prawidłowy bilans nawodnienia skóry. Jest ważnym czynnikiem w procesie odnowy komórki i naturalnym antyoksydantem skutecznie zwalczającym wolne rodniki oraz wzmacniającym mechanizmy naprawcze i ochronne skóry przed skutkami promieniowania ultrafioletowego. Wpływa hamująco na procesy starzenia się skóry, poprawia fakturę, ujędrnia i wygładza." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Ekstrakt z owoców borówki" desc="Bogaty w witaminy C, A, E oraz szereg minerałów, które wykazują właściwości przeciwutleniające i łagodzące. Dodatkowo działa przeciwstarzeniowo oraz wzmacnia naczynka krwionośne." />
                    <ActiveIngredient name="Ekstrakt z owoców aceroli" desc="To bogate źródło witamin C, A, B i PP. Antyoksydacyjna bomba witaminowa, która spowalnia procesy starzenia, chroni skórę przed czynnikami zewnętrznymi oraz silnie nawilża i ujędrnia." />
                    <ActiveIngredient name="Panthenol" desc="Prowitamina B5, która przenikając do głębszych warstw skóry, nawilża ją i łagodzi podrażnienia. Dodatkowo redukuje transepidermalną utratę wody (TEWL) i przywraca równowagę hydrolipidową." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">
                <Effectivness>
                    <EffectivnessCard color="blue" percentage="90" desc="badanych potwierdza, że produkt zmniejsza szorstkość skóry"/>
                    <EffectivnessCard color="blue" percentage="85" desc="badanych potwierdza, że produkt zmniejsza widoczność pierwszych zmarszczek"/>
                    <EffectivnessCard color="blue" percentage="75" desc="badanych potwierdza, że produkt reguluje wydzielanie sebum i ogranicza błyszczenie skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
